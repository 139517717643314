import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)


const routes = [
  {
    path:"/",
    redirect :"/login"
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/success',
    name: 'PaySuccess',
    component: () => import('../views/PaySuccessfulPage')
  },
  {
    path: '/failed',
    name: 'PayFailed',
    component: () => import('../views/PayFailedPage')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/Payment')
  },
  {
    path: '/register/:id?',
    name: 'Register',
    component: () => import('../views/login/Register.vue'),
    props: true
  },
  {
    path: '/homeIndex',
    name: 'homeIndex',
    component: ()=> import('../index/index.vue')
  },
  {
    path: '/personalReport',//个人报告
    name: 'personalReport',
    component: () => import('@/report/personal-report/index.vue')
  },
  {
    path: '/enterpriseReport',//企业报告
    name: 'enterpriseReport',
    component: () => import('@/report/enterprise-report/index.vue')
  },
  {
    path: '/housekeepingReport',//家政报告
    name: 'housekeepingReport',
    component: () => import('@/report/housekeeping-report/index.vue')
  },
  {
    path: '/rentalReport',//租赁报告
    name: 'rentalReport',
    component: () => import('@/report/rental-report/index.vue')
  },
  {
    path: '/test',//个人 租赁  家政
    name: 'test',
    component: () =>  import('@/report-info/personal-info.vue')
  },
  {
    path: '/test2',//企业报告
    name: 'test2',
    component: () =>  import('@/report-info/enterprise-info.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
