import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import httpRequest from "@/api/HttpRequest";
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import echarts from 'echarts'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(Antd)
Vue.config.productionTip = false
Vue.prototype.$http = httpRequest
Vue.prototype.$echarts = echarts

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
